// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faArrowRight      as fasFaArrowRight      } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faBars            as fasFaBars            } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown       as fasFaCaretDown       } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft       as fasFaCaretLeft       } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight      as fasFaCaretRight      } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircleQuestion  as fasFaCircleQuestion  } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser      as fasFaCircleUser      } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faClock           as fasFaClock           } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment         as fasFaComment         } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder          as fasFaFolder          } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMagnifyingGlass as fasFaMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faTag             as fasFaTag             } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faXmark           as fasFaXmark           } from "@fortawesome/pro-solid-svg-icons/faXmark";

import { faBaseball         as farFaBaseball        } from "@fortawesome/pro-regular-svg-icons/faBaseball";
import { faBenchTree        as farFaBenchTree       } from "@fortawesome/pro-regular-svg-icons/faBenchTree";
import { faBook             as farFaBook            } from "@fortawesome/pro-regular-svg-icons/faBook";
import { faCalendar         as farFaCalendar        } from "@fortawesome/pro-regular-svg-icons/faCalendar";
import { faFlagPennant      as farFaFlagPennant     } from "@fortawesome/pro-regular-svg-icons/faFlagPennant";
import { faMousePointer     as farFaMousePointer    } from "@fortawesome/pro-regular-svg-icons/faMousePointer";

import { faFacebookF        as fabFaFacebookF       } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faInstagram        as fabFaInstagram       } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedinIn       as fabFaLinkedinIn      } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTwitter          as fabFaTwitter         } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faPinterest        as fabFaPinterest       } from "@fortawesome/free-brands-svg-icons/faPinterest";

/**
 * Add solid icons
 */
library.add(fasFaArrowRight, fasFaBars, fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaCircleQuestion, fasFaCircleUser, fasFaClock, fasFaComment, fasFaFolder, fasFaMagnifyingGlass, fasFaTag, fasFaXmark);

/**
 * Add regular icons
 */
library.add(farFaBaseball, farFaBenchTree, farFaBook, farFaCalendar, farFaFlagPennant, farFaMousePointer);

/**
 * Add brand icons
 */
library.add(fabFaFacebookF, fabFaInstagram, fabFaLinkedinIn, fabFaTwitter, fabFaPinterest);


/**
 * Watch the DOM to insert icons
 */
dom.watch();
